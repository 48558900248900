var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.newMarkdownFileDialog),callback:function ($$v) {_vm.newMarkdownFileDialog=$$v},expression:"newMarkdownFileDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Create New Markdown File")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"File Name","required":""},model:{value:(_vm.newFileName),callback:function ($$v) {_vm.newFileName=$$v},expression:"newFileName"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.newMarkdownFileDialog = false; _vm.newFileName = ''}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.createMarkdownFile}},[_vm._v(" Save ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-container',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("New ")],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.newMarkdownFileDialog = true}}},[_c('v-icon',[_vm._v("mdi-language-markdown")]),_c('v-list-item-title',[_vm._v(" Markdown File ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"9"}},[(_vm.me)?_c('div',{staticClass:"container text-align-right"},[_c('span',[_vm._v("Welcome, "+_vm._s(_vm.me.displayName))]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,false,1438415950)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":_vm.signOut}},[_vm._v(" Sign out ")])],1)],1)],1)],1):_vm._e()])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{staticClass:"file-explorer"},[_c('v-treeview',{attrs:{"items":_vm.items,"active":_vm.activeDriveItems,"load-children":_vm.loadChildren,"activatable":"","item-key":"id","open-on-click":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(!item.isFile)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',[_vm._v(" "+_vm._s(_vm.fileTypes[item.fileType])+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.selectDriveItem(item)}}},[_vm._v(_vm._s(item.name))])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('Editor')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }